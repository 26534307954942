/* FileName: app.scss */

* { 
	margin: 0; 
	padding: 0; 
	box-sizing: border-box; 
	font-family: "Gilroy", sans-serif; 
} 
.podcasts { 
	background: rgb(231, 235, 214); 
	background: linear-gradient( 
		180deg, 
		rgba(231, 235, 214, 1) 0%, 
		rgba(55, 102, 44, 1) 100%
	); 
} 

.podcasts-full { 
    position: fixed;   // Fixes the position relative to the viewport
    top: 0;            // Positions the top edge at the top of the viewport
    right: 0;          // Positions the right edge at the right of the viewport
    bottom: 0;         // Positions the bottom edge at the bottom of the viewport
    left: 0;           // Positions the left edge at the left of the viewport
    z-index: 1;        // Ensures it stays above other content (adjust as needed)

    background: rgb(231, 235, 214); 
    background: linear-gradient( 
        180deg, 
        rgba(231, 235, 214, 1) 0%, 
        rgba(55, 102, 44, 1) 100%
    ); 
}

@import "./library"; 
@import "./player"; 
@import "./song"; 
@import "./nav"; 
h2, 
h3 { 
	color: #133a1b; 
} 
h3, 
h4 { 
	font-weight: 600; 
} 
button { 
	font-weight: 700; 
} 
